// Global color variables
$primary-color: #ff6600;
$background-color: #282c34;
$text-color: white;
$link-color: #61dafb;

$primary-blue: #0676ce;
$light-blue: #e3ecf4;
$cyan: #0da6db;
$secondary-blue: #639fdc;
$accent-blue: #0597d3;
$dark-blue: #045bc5;
$light-cyan: #68cae8;
$lavender: #b19cd9;
