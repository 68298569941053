@import '../styles/variables';  // Import global variables

.footer-container {
  background-color: $secondary-blue; /* Matches the dark blue background from the image */
  padding: 60px 20px;
  margin-top: 100px;
  color: white;
  font-family: Arial, sans-serif;

  .cta-section {
    display: flex;
    justify-content: space-around;
    padding: 20px 100px;
    margin-bottom: 30px;

    .cta-card {
      background-color: white;
      color: #333;
      padding: 20px;
      width: 45%;
      text-align: center;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      p {
        font-size: 1rem;
        margin-bottom: 15px;
      }

      .cta-button {
        background-color: $primary-blue;
        color: white;
        padding: 10px 40px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }

  .footer-bottom {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    gap: 15px;

    .footer-links, .footer-legal, .footer-social {
      display: flex;
      justify-content: center;
      gap: 10px;

      a {
        color: white;
        text-decoration: none;
        font-size: 0.9rem;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .footer-social {
      i {
        font-size: 1.5rem;
      }
    }

    .footer-copyright {
      grid-column: span 3;
      text-align: center;
      font-size: 0.85rem;
      margin-top: 20px;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}
