// Colors
$primary-color: #ff6600;  // The orange for buttons and highlights
$background-color: #282c34;
$text-color: white;
$link-color: #61dafb;

// Additional Color Palette
$primary-blue: #0676ce;
$light-blue: #e3ecf4;
$cyan: #0da6db;
$secondary-blue: #639fdc;
$accent-blue: #0597d3;
$dark-blue: #045bc5;
$light-cyan: #68cae8;
$lavender: #b19cd9;  // Optional lavender

// General Styles
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: $background-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $text-color;
}

.App-link {
  color: $link-color;
}

// Keyframes
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// Navbar Specific Styles
.navbar-logo {
  font-weight: bold;
  color: $primary-blue;
}

.navbar-button {
  margin-right: 10px;
  color: $primary-color;  // Orange for buttons
}

.hero-section {
  background-color: $light-blue;
  padding: 50px;
  margin: 20px 0;  // Optional margin to separate sections
}

.middle-section {
  background-color: $cyan;
  padding: 50px;
  margin: 20px 0;  // Optional margin to separate sections
}

.bottom-section {
  background-color: $lavender;
  padding: 50px;
  margin: 20px 0;  // Optional margin to separate sections
}
