@import '../styles/variables';  // Import global variables

.billionaire-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background-color: $light-blue;  // Use the color variable
}

.billionaire-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  position: relative;
}

.overlay {
  position: absolute;
  bottom: -7%;  // Adjust to position overlay lower
  left: 50%;
  transform: translateX(-50%);

  width: 80%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.5);  // Semi-transparent black overlay
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.overlay-form {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);  // Create two columns for even distribution
  gap: 15px;

  .form-group {
    display: flex;
    flex-direction: column;
    color: white;

    label {
      margin-bottom: 5px;
    }

    input {
      padding: 10px;
      background-color: rgba(255, 255, 255, 0.2);  // Match transparency
      border: none;
      border-radius: 4px;
      color: white;
    }

    .update-btn {
      grid-column: span 2;  // Make the button span both columns
      padding: 10px;
      background-color: $lavender;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
