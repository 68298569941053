@import '../styles/variables';  // Import global variables

.slider-container {
  margin-top: 250px; /* Adjust margin to move the slider down */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slider-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four equal columns */
  align-items: center;
  width: 100%;
  opacity: 1;
  transition: opacity 0.5s ease-in-out; /* Smooth fade transition */
}

.fade-out {
  opacity: 0; /* Fade out effect */
}

.fade-in {
  opacity: 1; /* Fade in effect */
}

.slider-column {
  padding: 10px;
}

.slider-image {
  display: flex;
  justify-content: center; /* Center the image in the second column */
  img {
    width: 80%; /* Adjust image size */
    height: auto;
  }
}

.slider-text {
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.5;
  padding: 0 10px;
  grid-column: 3 / 4; /* The text is in the third column */
}

.dots {
  margin-top: 20px;
  .dot {
    height: 15px;
    width: 15px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
  .active {
    background-color: $light-blue; /* Active dot color */
  }
}
